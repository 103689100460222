:root{
    --rubikfly-blue: #279fb0;
    --slide-transform: 0;
    --transition: transform 1s;
    --button-background:#279fb0;
}

@font-face {
    font-family: Quicksand;
    src: url(Quicksand-Regular.ttf);
  }

body{
    background-color: white;
    margin: 0;
    padding: 0;
    margin-bottom: -20px;
    font-family: Quicksand!important;
}

html{
    margin: 0;
    padding: 0;
}


button{
    cursor: pointer;
}

.relative{
    position: relative;
}

.absolute{
    position: absolute;
}

.flex__vertical-align{
    display: flex;
    align-items: center;
}

.text__right{
    text-align: right;
}

.underline{
    border-bottom: 1px solid black;
}

.color-rubikfly{
    color: var(--rubikfly-blue);
}

.lower-latin-list{
    list-style-type: lower-latin;
}

.center-text{
    text-align: center;
}

.red-text{
    color: red;
}

.brand-color{
    color: var(--rubikfly-blue);
}

.width100{
    width: 100%;
}

.hidden{
    display: none;
}

.fixed{
    position: fixed;
}

.router-view{
    margin-top: 100px;
}

.titles-container{
    padding: 0 50px;
    max-width: 1200px;
    width: fit-content;
    margin: auto;
    text-align: center;
}

.total-price-text{
    text-align: center;
    color: var(--rubikfly-blue);
    font-size: 1.2em;
    font-weight: bold;
    margin: 20px;
}

@media (max-width: 800px){
    .titles-container{
        padding: 0 20px;
    }
}


@media (max-width: 500px){
    .hide-on-mobile{
        display: none;
    }
}
/*Nav*/

nav{
    display: flex;
    justify-content: space-between;
}


.navbar{
    padding: 0 0!important;
}

.navbar-container{
    top: 0;
    width: 100%;
    z-index: 10;
    background: #fff;
    border-bottom: 1px solid #DDD;
}

.main-nav h1{
    height: fit-content;
}

.nav-items span{
    height: fit-content;
}

.help-list-container{
    position: absolute;
    z-index: 3;
    top: 70px;
    background-color: #DDF;
    display: none;
}

.help-list-container ul{
    margin: 0;
    padding: 0px;
}

.help-list-container li{
    list-style: none;
    padding: 0;
}

.help-list-container a{
    margin: 0;
    height: 100%;
}

nav a{
    text-decoration: none;
    color: black;
}

nav .has-price{
    font-weight: bolder;
    font-size: 1.3rem;
}

nav a:hover{
    text-decoration: none;
    color: black;
}

.nav-items a:hover{
    background-color: var(--rubikfly-blue);
}

.help-list-container li:nth-child(1){
    border-bottom: 1px var(--rubikfly-blue);
}

.gift-img{
    width: 15px;
    margin-right: 5px;
}

.nav-item a{
    color: #666;
    font-weight: 600;
    font-size: 1em;
}

.nav-item a .rubikfly-gift:hover{
    color: var(--rubikfly-blue);
}

.main-nav{
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
}

.navbar-toggler-icon{
    color: var(--rubikfly-blue);
}

.navbar-toggler:focus{
    box-shadow: none!important;
}

.navbar-toggler{
    padding-right: 1.4rem!important;
}

.nav-bars-img{
    width: 30px;
}


.nav-item a:hover{
    color: var(--rubikfly-blue)!important;
}

.navbar-brand{
    margin-bottom: 0px;
    padding-left: 30px;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
}

.navbar-brand img{
    padding: 10px 0;
    width: 200px;
}

.nav-link{
    font-size: 0.9em;
}

.navbar-price{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    margin-right: 30px;
    font-weight: 800;
}

.navbar__total-price-text{
    font-size: 0.8rem;
}

.show-on-collapse{
    display: none;
}


@media (max-width: 991px){
    .nav-item:not(:last-child){
        border-bottom: 1px solid #CCC;
    }

    .navbar-collapse{
        padding: 10px;
    }

    .navbar-logo-button{
        display: flex;
        justify-content: space-between;
    }

    .main-nav.open{
        display: flex;
        align-items: flex-start!important;
        height: 100vh;
    }

    .navbar-collapse#collapsable-main-menu{
        flex-grow: 1;
        text-align: center;
    }

    .show-on-collapse{
        display: block;
    }
}

@media (min-width: 992px){
    .navbar-collapse{
        flex-direction: row-reverse;
    }

    .navbar-expand-lg div#collapsable-main-menu.navbar-collapse{
        flex-shrink: 0;
    }

    .navbar-nav{
        flex-wrap: wrap;
    }
}

/*Header*/

.header-img-container{
    position: relative;
}
.header-img{
    width: 100%;
    height: 500px;
    background-color: antiquewhite;
    background-image: url("/imgs/header3.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    opacity: 1;
}

.header-content{
    vertical-align: middle;
    margin: auto;
    width: 90%;
    max-width: 1500px;
    position: absolute;
    z-index: 1;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);

}

.header-content .header-text-mobile{
    display: none;
}

.header-content .header-text h1{
    color: white;
    text-shadow: 2px 2px 0 #000;
    text-align: end;
    font-size: 5rem;
    font-weight: 800;
}

.header-content .header-text h2{
    color: white;
    text-shadow: 2px 2px 0 #000;
    text-align: end;
    font-weight: 800;
    font-size: 2.25rem;
    margin-top: -10px;
}

.header-inputs-container{
    background-color: #ffffffe8;
    width: 100%;
    display: flex;
    padding: 35px;
    border-radius: 5px;
}

.header-inputs-inner-container{
    display: flex;
    flex-grow: 1;
}

.header-inputs{
    display: flex;
    padding: 0 0px;
    flex-grow: 1;
    gap: 10px;
}

.header-inputs-container button{
    border: 2px solid var(--rubikfly-blue);
    border-radius: 5px;
    color: var(--rubikfly-blue);
    padding: 5px 20px;
}

.header-inputs-container .button-selected{
    background-color: var(--rubikfly-blue);
    color: white;
}

.header-inputs-container .book-now{
    background-color: #dd4d93;
    color: white;
    font-weight: bold;
    height: fit-content;
    margin-top: 6px;
    padding: 17px;
    border: none;
}

.header-inputs-container .book-now-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 11px;
}

.header-input{
    padding: 0 0px;
    flex-grow: 1;
}

.header-inputs-inner-container label{
    display: block;
    font-size: 0.8em;
    font-weight: bold;
    color: var(--rubikfly-blue);
}

.header-input input{
    border: none;
    background-color: #FFF;
}

.header-input select{
    padding: 17px 0px;
    border: 1px solid var(--rubikfly-blue);
    background-color: #FFF;
    width: 100%;
}


.up-down-img{
    width: 12px;
    margin: -2px 5px;
}

.people-input{
    position: relative;
    border: 1px solid var(--rubikfly-blue);
}

.inner-people-input{
    position: absolute;
    background-color: #FFF;
    width: 200px;
    padding: 0 10px;
    box-shadow: 0 0 10px #999;
    display: none;
}

.inner-people-input ul{
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.inner-people-input li{
    list-style: none;
}

.inner-people-input li:not(:first-child){
    border-top: 1px solid #999;
}

.change-people-number{
    border-radius: 50%;
    border: 1px solid var(--rubikfly-blue);
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
}

.header-inputs .people-number{
    background-color: white;
    padding: 17px;
    margin: 0;
}

.header-buttons{
    background-color: white;
    padding: 10px;
}

.header-buttons button{
    margin-left: 2px;
    margin-right: 2px;
}


.people-type-item{
    display: flex;
}

.people-type-name{
    width: 100px;
}

.people-type-item span{
    font-size: 0.8em;
}

.add-remove-people{
    display: grid;
    width: 100px;
    grid-template-columns: 33% 33% 33%;
}

.add-remove-people span{
    text-align: center;
    margin: auto;
}

@media (min-width: 1086px){
    .header-inputs{
        padding: 0 10px;
    }
}

@media (max-width: 1085px){
    .header-buttons{
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .header-inputs{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .header-inputs select{
        width: 100%;
    }

    .header-inputs #type{
        width: 100%;
    }

    .header-inputs-inner-container{
        display: grid;
        grid-template-columns: 100%;
    }

    .header-inputs-container{
        display: grid;
        grid-template-columns: 100%;
    }

    .header-text{
        display: none;
    }

    .header-inputs-container .book-now{
        width: 100%;
    }
}

@media (max-width: 500px){
    .header-inputs-inner-container{
        display: grid;
        grid-template-columns: 100%;
    }

    .header-inputs-container{
        margin: auto;
    }

    .header-inputs{
        display: grid;
        grid-template-columns: 100%;
    }

    .header-input select{
        padding: 3px;
        border: 1px solid var(--rubikfly-blue);
    }

    .header-inputs .people-number{
        background-color: white;
        padding: 3px;
    }

    .header-inputs-container .book-now{
        background-color: #dd4d93;
        color: white;
        font-weight: bold;
        height: fit-content;
        margin-top: 12px;
        padding: 3px 15px;
        border: none;
    }

    .header-inputs-container button{
        padding: 3px 5px;
    }

    .header-img{
        width: 100%;
        height: calc(140vh - 62px);
        background-color: antiquewhite;
        background-image: url(/imgs/header3.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
        opacity: 1;
    }

    .header-content .header-text-mobile{
        display: block;
        color: white;
        text-align: center;
        text-shadow: 2px 2px #000;
        margin-bottom: 50px;
    }

    .header-content .header-text-mobile h1{
        font-weight: 800;
        font-size: 2.2rem;
    }

    .header-content .header-text-mobile h2{
        font-size: 1.4rem;
    }

    .header-inputs-container{
        background-color: #FFFC;
    }

}


/*Destinations*/
.destinations-component{
    padding: 50px 0;
}


.destination-img-container{
    height: 220px;
    width: 220px;
    background-size: cover;
    background-position: center;
    border-radius: 25px;
    color: white;
}

.destination-component-container{
    width: 100%;
}



.destinations-container{
    overflow: hidden;
}

.destinations-container .width-percent{
    /*width: 15%;*/
}

.destinations-inner-container{
    display: flex;
    gap: 10px;
    height: 300px;
    width: fit-content;
    margin: auto;
}


.destinations-inner-container .top{
    margin-top: 0;
}
.destinations-inner-container .middle{
    margin-top: 40px;
}
.destinations-inner-container .bottom{
    margin-top: 80px;
}

.flex{
    display: flex;
}

.flex__col{
    display: flex;
    flex-direction: column;
}

.flex__space-between{
    display: flex;
    justify-content: space-between;
}

.destination-img-container h4{
    width: fit-content;
    height: fit-content;
    padding: 5px;
    margin: auto;
    font-size: 1.8rem;
    color: #FFF;
    font-weight: 600;
    text-shadow: 0 0 5px #000;
}

@media (max-width: 600px){
    .destination-img-container{
        width: 150px;
        height: 150px;
    }
    .destination-img-container h4{
        font-size: 0.7em;
    }
    .destinations-inner-container{
        height: 225px;
    }
}

.destinations-component h2{
    margin: 0;
}

.destinations-component h2{
    font-weight: 700;
    font-size: 2.5rem;
}

.destinations-component h5{
    margin-top: 10px;
}

.destinations-component .titles-container{
    margin-bottom: 30px;
}

.destinations-component h3{
    color: #999;
    font-size: 1.2em;
}


/*Opinions*/
.opinions-component-container{
    background-color: #c9e0f0;
    margin-top: 20px;
    padding: 50px 20px;
}

.opinions-component-container h3{
    color: grey;
    font-size: 1em;

}

.opinions-component-container h2{
    font-weight: 700;
    font-size: 2.5rem;
}

.opinion-container{
    height: 275px;
    width: 275px;
    background-color: white;
    padding: 40px 10px;
    border-radius: 20px;
    /*box-shadow: 0 0 5px #999;*/

}

.opinion-container .see-more{
    width: fit-content;
    margin: auto;
}

.opinion-container a{
    color: #000;
    text-decoration: none;
    font-size: 0.7em;
    font-weight: bold;
}

.opinion-container a:hover{
    color: #000;
    text-decoration: none;
}

.opinion-container .img-outter-container{
    padding: 10px;
}

.opinion-container .img-container{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    background-color: var(--rubikfly-blue);
    color: white;
    background-size: cover;
    background-position: center;
}

.opinion-container .opinion-info-container{
    padding: 5px;
    display: flex;
}

.opinion-container .opinion-info{
    padding-left: 5px;
}

.opinion-container .opinion-info .name{
    font-size: 1.2em;
}

.opinion-container .opinion-info .stars{
    display: flex;
}

.opinion-container .opinion-info .stars img{
    width: 15px;
}

.opinion-container .opinion-info .date{
    font-size: 0.5em;
    color: #444;
}

.inline-block{
    display: inline-block;
}

/*Opinions slider*/
.opinions-component-container .slider-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 10px;
}

.opinions-component-container .slider {
    display: flex;
    transform: translateX(var(--slide-transform));
    transition: var(--transition);
    gap: 20px;
}

.opinions-component-container .slider__element {
    min-width: 300px;
}

.opinions-component-container .slider-buttons {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
}

.opinions-component-container .button {
    background: none;
    border: none;
    background-color: grey;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    color: #ffffff;
}

.opinions-component-container .button:active {
    transform: scale(0.9);
}


.carousel-item-container{
    width: fit-content;
    margin: auto;
}

.carousel-control-next-icon-container, .carousel-control-prev-icon-container{
    background-color: black!important;
    width: fit-content;
    height: fit-content;
    padding: 10px 12px 5px 12px;
    border-radius: 50%;
}

.carousel-control-next-icon, .carousel-control-prev-icon{
    margin: auto;
}

.carousel-control-next, .carousel-control-prev{
    width: fit-content;
}

.opinion-component-container{
    margin: 10px;
}

.opinion-container .opinion{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 70px;
}

/* Experiences */
.experiences-component-container{
    padding: 50px 20px;
}

.experiences-component-container h3{
    color: #999;
    font-size: 1.2em;
}

.experiences-component-container h5{
    font-size: 1.2em;
}

.experiences-component-container h2{
    font-weight: 700;
    font-size: 2.5rem
}

.experiences-container{
    width: 90%;
    max-width: 1000px;
    margin: auto;
    display: grid;
    gap: 20px;
    grid-template-columns: 30% 40% 30%;
    padding: 30px 0;
}

.experiences-container .experience{
    border-radius: 25px;
}

.experiences-container .experience-img{
    border-radius: 25px;
    margin-bottom: 10px;
}

.experiences-container .experience1{
    width: 100%;
    height: fit-content;
    box-shadow: 0 0 5px #999;
    overflow: hidden;

}

.experiences-container h4{

}

.experiences-container .experience1-img{
    width: 100%;
    min-height: 100px;
    aspect-ratio: 3/2;
    background-image: url('../../public/imgs/experience1.webp');
    background-size: cover;
    background-position: center;
    position: relative;
}

.experiences-container .experience1 h4{
    font-size: 1.1em;
    background-color: rgb(68 164 68);
    text-align: center;
    width: 100%;
    padding: 8px;
    margin: 0;
    color: white;

}

.experiences-container .experience1 p{
    padding: 20px;
}

.experiences-container .experience2{
    width: 100%;
    height: fit-content;
    box-shadow: 0 0 5px #999;
    overflow: hidden;

}

.experiences-container .experience2-img{
    width: 100%;
    min-height: 160px;
    aspect-ratio: 3/2;
    background-image: url('../../public/imgs/experience2.webp');
    background-size: cover;
    background-position: center;
    position: relative;
}

.experiences-container .experience2 h4{
    font-size: 1.1em;
    background-color: var(--rubikfly-blue);
    text-align: center;
    width: 100%;
    padding: 8px;
    margin: 0;
    color: white;

}

.experiences-container .experience2 p{
    padding: 20px;
}

.experiences-container .experience3{
    width: 100%;
    height: fit-content;
    box-shadow: 0 0 5px #999;
    overflow: hidden;

}

.experiences-container .experience3-img{
    width: 100%;
    min-height: 100px;
    aspect-ratio: 3/2;
    background-image: url('../../public/imgs/experience3.webp');
    background-size: cover;
    background-position: center;
    position: relative;
}

.experiences-container .experience3 h4{
    font-size: 1.1em;
    background-color: rgb(255 117 117);
    text-align: center;
    width: 100%;
    padding: 8px;
    margin: 0;
    color: white;

}

.experiences-container .experience3 p{
    padding: 20px;
}

@media (max-width: 700px){
    .experiences-container{
        width: 90%;
        margin: auto;
        display: grid;
        grid-template-columns: 100%;
    }

    .experiences-container .experience1-img, .experiences-container .experience2-img, .experiences-container .experience3-img{
        height: 200px;
    }
}

/*Promotions*/
.promotions-container{
    max-width: 1000px;
    width: 90%;
    margin: auto;
    text-align: center;
    padding: 50px 20px;
}

.promotions-container input{
    border-radius: 20px;
    padding: 5px 15px;
    border: 1px solid #444;
    margin: 10px;
}

.promotions-container .email{
    width: 300px;
}

.promotions-container .send{
    padding: 5px 30px;
    background: var(--button-background);
    border: none;
    color: white;
}

/*Hotels*/
.hotel-component{
    width: 100%;
    height: 100%;
    background-color: #FFF;
    box-shadow: 0 0 5px #999;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

.hotels-container{
    max-width: 1200px;
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 31% 31% 31%;
    gap: 40px;
}

.hotels-component .hotels-description{
    font-size: 1.25rem;
    line-height: 1.5em;
    max-width: 800px;
    width: 90%;
    margin: auto;
    text-align: center;
}

.hotels-component-titles{
    padding: 30px 0 60px 0;
}

.hotels-component h1{
    text-align: center;
    font-weight: 700;
}

.hotel-component .img-container{
    width: 100%;
    height: 200px;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
}

.hotel-info .main-info{
    display: flex;
}

.hotel-info{
    padding: 10px;
}

.hotel-component .hotel-info .stars{
    display: flex;
    margin: 0 auto;
    height: fit-content;
}

.hotel-component .hotel-info .booking-punctuation{
    display: flex;
    flex-direction: column;
    margin: auto;
}

.hotel-component .hotel-info .stars img{
    width: 15px;
}

.hotel-component .hotel-info .valorations{
    display: flex;
    margin-top: 10px;
    width: 100%;
}

.hotel-component .hotel-info p{
    font-size: 0.8em;
    margin-top: 0;
    margin-bottom: 0;
}

.hotel-component .hotel-info .opinion{
    font-size: 1.1em;
    font-weight: 600;
}

.hotel-component .hotel-info .comments-number{
    font-size: 0.6em;
}

.hotel-component .hotel-info .ubication{
    font-size: 0.6em;
    color: var(--rubikfly-blue);
}

.hotel-component .hotel-info .punctuation{
    font-size: 1.2em;
    display: flex;
    align-items: center;
    padding: 5px;
}

.hotel-component .hotel-info .punctuation-container{
    background-color: var(--rubikfly-blue);
    border-radius: 5px;
    padding: 5px 8px;
    color: white;
}

.hotels-component .see-more-container{
    padding: 50px;
    width: fit-content;
    margin: auto;
}

.hotels-component .see-more-container button{
    padding: 10px 30px;
    background: var(--button-background);
    border: none;
    color: white;
    border-radius: 20px;
}

.hotel-component .hotel-info .valorations-container{
    height: 60px;
}

.hotel-component .hotel-info .valorations{
    position: absolute;
    bottom: 10px;
    left: 10px;
}

@media (max-width: 1000px){
    .hotels-container{
        grid-template-columns: 48% 48%;
    }
}

@media (max-width: 500px){
    .hotels-container{
        grid-template-columns: 100%;
    }
}

/*Calendar*/
.breadcrumb-container{
    max-width: 1000px;
    width: 90%;
    margin: auto;
}

ul.breadcrumb {
    padding: 10px 16px;
    list-style: none;
    background-color: #eee;
}
ul.breadcrumb li {
    display: inline;
    font-size: 18px;
}
ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: ">\00a0";
}
ul.breadcrumb li a {
    color: #0275d8;
    text-decoration: none;
}
ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
}

@media (min-width: 640px) {
    .vc-pane:not(:first-child){
        border-left: 1px solid #cbd5e0;
        border-radius: 25px;
    }

    .vc-container.vc-is-expanded{
        border-radius: 25px;
    }
}

@media (max-width: 639px) {
    .vc-pane:not(:first-child){
        border-top: 1px solid #cbd5e0;
        border-radius: 25px;
    }

    .vc-container.vc-is-expanded{
        border-radius: 25px;
    }
}

.vc-day{
    color: var(--rubikfly-blue);
}

.calendar-component h4{
    font-size: 1.2em;
}

.calendar-component .calendar-container{
    max-width: 1200px;
    width: 90%;
    margin: 40px auto;
}

.calendar-component .calendar-titles{
    width: 90%;
    max-width: 1000px;
    margin: auto;
    text-align: center;
}

.calendar-component .rates{
    max-width: 1000px;
    width: 90%;
    margin: auto;
    padding: 10px 16px;
    list-style: none;
    background-color: #eee;
    display:  flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    border-radius: 25px;
}

.calendar-component .rates li{
    display: inline;
}

.calendar-component .rates li div{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
}

.calendar-component .rates li .high-demand{
    background-color: #FFBB00;
}

.calendar-component .rates li .last-places{
    background-color: #FF0000;
}

.calendar-component .rates li .blocked{
    background-color: #CCC;
}

.calendar-component .rates li .standard{
    background-color: var(--rubikfly-blue);
}

.calendar-component .calendar-button-container{
    width: fit-content;
    margin: 30px auto;
}

.calendar-component .calendar-button-container button{
    padding: 10px 30px;
    background: var(--button-background);
    border: none;
    color: white;
    border-radius: 20px;
}

.calendar-component .calendar-button-container button.disabled{
    background: #999;
    cursor: auto;
}

@media (max-width: 500px) {
    .calendar-component .rates{
        max-width: 1000px;
        width: 90%;
        margin: auto;
        padding: 10px 16px;
        font-size: 0.8rem;
        list-style: none;
        background-color: #eee;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}

@media (max-width: 350px) {
    .calendar-component .rates{
        max-width: 1000px;
        width: 90%;
        margin: auto;
        padding: 10px 16px;
        font-size: 0.8rem;
        list-style: none;
        background-color: #eee;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
    }
}

/*Destinations view*/
.destination-view-component{
    max-width: 1400px;
    width: 100%;
    margin: auto;
    background-color: #fff;
    border-radius: 25px;
    position: relative;
}

.destinations-view-component h4{
    font-size: 1.2em;
    text-align: center;
}

.destinations-view-component h2{
    text-align: center;
}

.destinations-modal{
    background-color: #3336;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    display: none;
}

.destinations-modal-content{
    background-color: #fefefe;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.destinations-modal-inner{
    width: 100%;
}

.destinations-modal-content__close{
    float: right;
    cursor: pointer;
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
    color: white;
    background-color: red;
    text-align: center;
    vertical-align: middle;
}

.destinations-modal-content__text{
    text-align: center;
    padding: 5px;
    width: 100%;
}


.destination-view-component .destination-container{
    width: 200px;
    height: 150px;
    border-radius: 25px;
    background-position: center;
    background-size: cover;
    cursor: pointer;
}

.destinations-view-component .destinations-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    max-width: 1250px;
    margin: auto;
    width: 90%;
}

.destinations-view-component .titles{
    max-width: 1000px;
    width: 90%;
    margin: auto;
    margin-bottom: 50px;
}

.destination-view-component .destination-container h2{
    width: fit-content;
    height: fit-content;
    padding: 5px;
    margin: auto;
    font-size: 1.5em;
    color: white;
    text-shadow: 0 0 5px #000;
}

.destinations-button-container{
    width: fit-content;
    margin: 30px auto;
}

.destinations-button-container button{
    padding: 10px 30px;
    background: var(--button-background);
    border: none;
    color: white;
    border-radius: 20px;
}

.discarded-destination-text{
    position: absolute;
    color: white;
    font-weight: bold;
    left: 0;
    right: 0;
    bottom: 3px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    z-index: 3;
}

@media (max-width: 500px) {
    .destination-view-component .destination-container{
        width: 100px;
        height: 100px;
        border-radius: 25px;
        font-size: 0.6rem;
        background-position: center;
        background-size: cover;
        cursor: pointer;
    }

    .discarded-destination-text{
        font-size: 0.9rem;
    }
}

/*Extras*/
.extra-container{
    background-color: #ececec;
}

.extra-container .info-img{
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 20px;
    cursor: pointer;
}

.extras-component h3, .extras-component h6{
    max-width: 1000px;
    width: 90%;
    margin: 20px auto;
    text-align: center;
}

.extras-component .hotel-container, .extras-component .insurance-container{
    max-width: 1000px;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 20px;
}

.extras-component .seat-container{
    max-width: 1000px;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.extras-component .hotel-container .extra-container{
    width: 100%;
    box-shadow: 0 0 5px #999;
    border-radius: 25px;
    overflow: hidden;
}

.extras-component .seat-container .extra-container{
    width: 100%;
    box-shadow: 0 0 5px #999;
    border-radius: 25px;
    overflow: hidden;
}

.extras-component .insurance-container .extra-container{
    width: 100%;
    box-shadow: 0 0 5px #999;
    border-radius: 25px;
    overflow: hidden;
}

.extras-component .extra-img{
    border-radius: 25px;
}

.extras-component .hotel-container .extra-container .h3or4-stars-img{
    background-image: url("/imgs/hotel3o4.webp");
    background-position: center;
    background-size: cover;
    height: 200px;
    box-shadow: 0 1px 6px -2px gray
}

.extras-component .hotel-container .extra-container .higher-hotel-img{
    background-image: url("/imgs/hotel-desayuno.webp");
    background-position: center;
    background-size: cover;
    height: 200px;
    box-shadow: 0 1px 6px -2px gray
}

.extras-component .seat-container .extra-container .choose-seats-img{
    background-image: url("/imgs/asientos-asignados.webp");
    background-position: center;
    background-size: cover;
    height: 200px;
    box-shadow: 0 1px 6px -2px gray
}

.extras-component .seat-container .extra-container .dont-choose-seats-img{
    background-image: url("/imgs/asientos-no-asignados.webp");
    background-position: center;
    background-size: cover;
    height: 200px;
    box-shadow: 0 1px 6px -2px gray
}

.extras-component .insurance-container .extra-container .cancellation-insurance-img{
    background-image: url("/imgs/seguro-cancelacion2.webp");
    background-position: center;
    background-size: cover;
    height: 200px;
    border-radius: 25px;
    box-shadow: 0 1px 6px -2px gray
}

.extras-component .insurance-container .extra-container .trip-insurance-img{
    background-image: url("/imgs/seguro-viaje2.webp");
    background-position: center;
    background-size: cover;
    height: 200px;
    box-shadow: 0 1px 6px -2px gray
}

.extras-component .hotel-container .extra-container .texts, .extras-component .insurance-container .extra-container .texts{
    padding: 20px;
}

.extras-component .seat-container .extra-container .texts{
    padding: 20px;
}

.extras-component .hotel-container .extra-container h4, .extras-component .insurance-container .extra-container h4{
    text-align: center;
    margin: 0;
}


.extras-component .hotel-container .extra-container p, .extras-component .insurance-container .extra-container p{
    text-align: center;
    margin: 0;
}


.extras-component .seat-container .extra-container h4{
    text-align: center;
    margin: 0;
}

.extras-component .seat-container .extra-container p{
    text-align: center;
    margin: 0;
}

.extras-component .hotel-container .extra-container .cost, .extras-component .insurance-container .extra-container .cost{
    font-size: 1.8em;
    font-weight: bold;
}

.extras-component .seat-container .extra-container .cost{
    font-size: 1.8em;
    font-weight: bold;
}

.extra-container .gratis{
    font-size: 1.8em;
    font-weight: bold;
    background: #ec5d0a;
    background: var(--button-background);
    background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    text-align: center;
}

.extras-component .button-container{
    margin: auto;
    width: fit-content;
    margin-bottom: 20px;
}

.extras-component button{
    padding: 10px 30px;
    border: none;
    border-radius: 20px;
}

.extras-component button.selected{
    background: var(--button-background);
    color: white;
}

.extras-component .hotels-type-container, .extras-component .insurances-container{
    margin: 30px 0;
}

.extras-component .hotels-type-container, .extras-component .insurances-container, .extras-component .departure-return-container, .extras-component .baggage-container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.extras-component .seats-container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.extras-component .insurances-container, .extras-component .baggage-container{
    background-color: #FAFAFA;
    padding: 30px 0 60px 0;
}

.extras-component .departure-flight, .extras-component .return-flight{
    padding: 0px 0 60px 0;
    border-radius: 25px;
}

.extras-component .departure-flight-img{
    background-image: url("/imgs/vueloida.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    border-radius: 25px;
    margin-bottom: 20px;
    box-shadow: 0 1px 6px -2px gray
}

.extras-component .return-flight-img{
    background-image: url("/imgs/vuelovuelta.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    border-radius: 25px;
    margin-bottom: 20px;
    box-shadow: 0 1px 6px -2px gray;
}


@media (max-width: 650px){
    .extras-component .hotel-container{
        grid-template-columns: 100%;
    }

    .extras-component .insurance-container{
        grid-template-columns: 100%;
    }

    .extras-component .baggage-inner-container{
        grid-template-columns: 100%;
    }

    .extras-component .seat-container{
        grid-template-columns: 100%;
    }
}



/*Extras multi range*/

.departure-return-container{
    padding-top: 30px;
}

.deperture-return-inner-container{
    margin-top: 20px;
}

.departure-return-container .middle{
    position: relative;
    max-width: 300px;
    width: 90%;
    margin: auto;
    height: 40px;
    padding: 20px;
}

.departure-return-container .middle h5{
    text-align: center;
}

.departure-return-container .price{
    font-size: 2rem;
    font-weight: bold;
}

.departure-return-container .slider{
    position: relative;
    z-index: 1;
    height: 10px;
    margin: 0 15px;
    width: 100%;
}

.multi-range-slider{
    margin-left: -20px;
}

.departure-return-container .slider > .track {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #FFF;
}

.departure-return-container .slider > .range{
    position: absolute;
    z-index: 2;
    left: 0%;
    right: 0%;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: var(--rubikfly-blue);
}

.departure-return-container .slider > .thumb{
    position: absolute;
    z-index: 3;
    width: 30px;
    height: 30px;
    background-color: var(--rubikfly-blue);
    border-radius: 50%;
}

.departure-return-container #thumb-left{
    left: 0%;
    transform: translate(-15px, -10px);
}

.departure-return-container #return-thumb-left{
    left: 0%;
    transform: translate(-15px, -10px);
}

.departure-return-container #thumb-right{
    right: 0%;
    transform: translate(15px, -10px);
}

.departure-return-container #return-thumb-right{
    right: 0%;
    transform: translate(15px, -10px);
}

.departure-return-container input[type=range]{
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    width: 100%;
    opacity: 0;
}

.departure-return-container input[type=range]::-webkit-slider-thumb{
    pointer-events: all;
    width: 30px;
    height: 30px;
    border-radius: 0;
    border: 0 none;
    background-color: red;
    -webkit-appearance: none;
}

.multi-range-hours{
    position: relative;
    width: 270px;
    margin: auto;
}

.multi-range-hours .hour{
    position: absolute;
}

.multi-range-hours .hour6{
    left: 0%;
    transform: translate(-50%, 10px);
}

.multi-range-hours .hour12{
    left: 33%;
    transform: translate(-50%, 10px);
}


.multi-range-hours .hour18{
    left: 66%;
    transform: translate(-50%, 10px);
}

.multi-range-hours .hour24{
    left: 100%;
    transform: translate(-50%, 10px);
}

.deperture-return-inner-container{
    max-width: 1000px;
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 40px;
}



@media (max-width: 800px){
    .deperture-return-inner-container{
        grid-template-columns: 1fr;
    }
}

/*Baggage*/
.baggage-container{
    margin-top: 100px;
    margin-bottom: 30px;
}

.baggage-img img, .case-img img{
    height: 150px;
}

.baggage-img, .case-img{
    width: fit-content;
    margin: auto;
    padding: 20px;

}

.baggage-container .extra-container{
    width: 100%;
    box-shadow: 0 0 5px #999;
    border-radius: 25px;
    overflow: hidden;
    padding: 0 0 20px 0;
}

.baggage-container .case-img{
    background-image: url("/imgs/equipaje-mano.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    width: 100%;
    box-shadow: 0 1px 6px -2px gray
}

.baggage-container .baggage-img{
    background-image: url("/imgs/facturar-maletas.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    width: 100%;
    box-shadow: 0 1px 6px -2px gray
}

.baggage-inner-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 1000px;
    width: 90%;
    margin: auto;
    margin-top: 50px;
}

.baggage-amount-container img{
    width: 20px;
}

.baggage-amount-container{
    display: flex;
    justify-content: center;
}

.baggage-amount-container .baggage-num{
    padding: 0 20px;
}

.baggage-amount-container .change-value{
    border: 1px solid var(--rubikfly-blue);
    border-radius: 50%;
    width: fit-content;
    height: fit-content;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.baggage-container .texts{
    padding-top: 20px;
}

.baggage-container h4{
    text-align: center;
}

.baggage-container p{
    text-align: center;
}

.baggage-container h5{
    text-align: center;
}

.extra-container button{
    border: 2px solid #ec0a68;
}

.extra-selected{
    background-color: #abdae0;
}

.extras-component .extras-button-container button{
    padding: 10px 30px;
    background: var(--button-background);
    border: none;
    color: white;
    border-radius: 20px;
}

.extras-button-container{
    width: fit-content;
    margin: 30px auto 100px auto;
}

/*Finalize*/
.finalize-component .traveler-header{
    background-color: var(--rubikfly-blue);
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 20px auto;
    width: 90%;
}

.traveler-header h3{
    padding-top: 5px;
    font-size: 1.2rem;
}


.finalize-modal{
    background-color: #3336;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    display: none;
}

.finalize-modal-content{
    background-color: #fefefe;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 40px;
    border: 1px solid #888;
    width: 80%;
    max-width: 800px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.finalize-modal-content .flex{
    gap: 20px;
}

.finalize-modal-inner{
    width: 100%;
}

.finalize-modal-content__close{
    float: right;
    cursor: pointer;
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
    color: white;
    background-color: red;
    text-align: center;
    vertical-align: middle;
}

.finalize-modal-content__text{
    padding: 5px;
    width: 100%;
}

.finalize-modal-content__title{
    padding: 5px;
    width: 100%;
    font-weight: 800;
    color: #ec0a68;
}

.finalize-modal-content__button{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    background-color: var(--button-background);
    color: #FFF;
    border-radius: 20px;
    padding: 10px 30px;
    border: none;
}

.finalize-component .traveler-header img{
    margin-top: 1px;
    width: 20px;
}

.birthday-container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.document-container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.expire-date-container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.traveler-form{
    width: 90%;
    padding: 20px;
    margin: auto;
    background-color: #EBEBEB;
}


.finalize-component .contact-data{
    max-width: 1000px;
    width: 90%;
    margin: auto;
    padding: 40px 20px;
}

.finalize-component .observations{
    width: 90%;
    margin: auto;
    margin-top: 20px;
}

.finalize-component .observations__textarea{
    width: 100%;
    resize: none;
    margin-top: 10px;
    padding: 10px;
    border: none;
    outline: none;
    box-shadow: 0 0 2px #999;
}

.finalize-component .observations__textarea:hover{
    outline: none;
}

.finalize-component .contact-data .address input{
    width: 100%;
}

.finalize-component .address-container{
    margin-top: 20px;
}

.finalize-component .contact-data-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
}

.finalize-component .name-surname-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
}

.finalize-component .contact-data-grid input{
    width: 100%;
}

.finalize-component .name-surname-grid input{
    width: 100%;
}

.finalize-component input:not([type='radio']){
    border: none;
    box-shadow: 0 0 2px #999;
    padding: 10px;
}

.finalize-component input:focus{
    outline: none;
}

.finalize-component .finalize-container{
    max-width: 1200px;
    width: 90%;
    margin: auto;
}

.finalize-component .booking-data{
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}

.finalize-component .booking-data h5{
    font-size: 1.1em;
    color: var(--rubikfly-blue);
}

.finalize-component .booking-data p{
    margin-bottom: 0;
}

.finalize-component .booking-data span img{
    width: 20px;
}

.finalize-component .booking-data ul{
    list-style: none;
    padding-left: 10px;
}

.finalize-component .booking-container{
    margin: 20px 0;
    box-shadow: 0 0 2px #999;
    background-color: #FFF;
    padding: 10px;
}

.finalize-component .finalize-message{
    text-align: center;
    color: green;
}

.finalize-component .error-message{
    color: red;
}

.finalize-component .name-container input, .finalize-component .surname-container input{
    width: 100%;
}

.finalize-component .nationality-container input{
    width: 100%;
}

.finalize-component .expire-date-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.finalize-component .expire-date-container input{
    width: 100%;
}

.finalize-component .birthday-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.finalize-component .birthday-container input{
    width: 100%;
}

.finalize-component .document-container{
    display: flex;
}

.finalize-component .document-number{
    flex-grow: 1;
}

.flex-gap-20{
    display: flex;
    gap: 20px;
}

.bold{
    font-weight: bold;
}

.finalize-component .price-text{
    color: var(--rubikfly-blue);
    font-weight: bold;
}

.finalize-component .gift-message{
    color: var(--rubikfly-blue);
    font-weight: bold;
    font-family: Quicksand;
    text-align: center;
    font-size: 1.1rem;
    margin-top: 40px;
}

.finalize-button-container{
    width: fit-content;
    margin: 30px auto;
}

.finalize-button-container button{
    padding: 10px 30px;
    background: var(--button-background);
    border: none;
    color: white;
    border-radius: 20px;
}

.finalize-button-container button.disabled-button{
    background: #999;
    cursor: auto;
}

.finalize-component .accept-terms-container{
    margin-top: 20px;
    text-align: center;
}

.finalize-component .booking-data{
    margin-top: 30px;
}

@media (max-width: 600px){
    .finalize-component .contact-data-grid{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        margin-top: 20px;
    }

    .finalize-modal-content{
        background-color: #fefefe;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 50px 40px;
        border: 1px solid #888;
        width: 80%;
        max-width: 800px;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
    }

}

@media (max-width: 400px){
    .finalize-component .contact-data-grid{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        margin-top: 20px;
    }

    .finalize-component .name-surname-grid{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        margin-top: 20px;
    }
}

@media (min-width: 800px){
    .finalize-component .finalize-container{
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        width: 90%;
        margin: auto;
    }

    .finalize-component .booking-data{
        position: sticky;
        top: 20px;
        width: 320px;
        height: fit-content;
        margin-top: 0;
    }

    .finalize-component .contact-data-container{
        flex-grow: 1;
    }
}


/*Footer*/
footer{
    background-color: #FFF;
    color: #000;
    margin-top: 50px;
    padding: 20px;
}

.footer-container{
    max-width: 1000px;
    width: 95%;
    margin: auto;
    display: grid;
    grid-template-columns: 66% 33%;
}

.footer-info{
    padding-left: 20px;
    padding-right: 20px;
}

.footer-menu{
    padding-left: 20px;
    padding-right: 20px;
}

.footer-menu{
    display: grid;
    grid-template-columns: 50% 50%;
}

.footer-menu .footer-list{
    width: fit-content;
    margin: auto;
}

.footer-menu li{
    list-style: none;
}

.footer-menu ul{
    padding: 0;
}

.footer-menu li a{
    color: #000;
    text-decoration: none;
}

.footer-menu li{
        font-size: 0.9em;
}

footer .logo{
    width: 200px;
}

@media (max-width: 800px){
    .footer-container{
        display: grid;
        grid-template-columns: 100%;
    }

    .footer-container .payments{
        margin: auto;
    }
}

@media (max-width: 400px){

    .footer-container{
        text-align: center;
    }

    .footer-menu{
        display: grid;
        grid-template-columns: 100%;
        margin: auto;
        text-align: center;
    }
}

/*ABOUT US*/

.about-us-view{
    font-size: 1.2rem;
}

.about-us-view .main{
    max-width: 1000px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.about-us-view .fly-img{
    max-width: 1000px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    aspect-ratio: 9 / 3;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.about-us-view .fly-img img{
    min-width: 100%;
    min-height: 100%;
}

.about-us-view h2{
    font-size: 3rem;
    font-weight: 800;
}

.about-us-view .section{
    margin-top: 90px;
}

.about-us-view .last-section{
    margin-bottom: 200px;
}

.about-us-view .margin0{
    margin: 0;
}

@media (max-width: 500px){
    .about-us-view .section-top{
        margin-top: 30px;
    }
}

/*Cookies Policy*/

.cookies-banner{
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    font-size: 1.1rem;
    transform: translateX(-50%);
    background-color: var(--rubikfly-blue);
    color: #FFF;
    padding: 10px;
    padding-top: 40px;
    z-index: 9;
    text-align: center;
}

.decline-cookies{
    padding: 5px 20px;
    font-weight: 400;
}

.cookies-banner a{
    text-decoration: none;
}

.accept-cookies{
    padding: 5px 20px;
}

.cookies-buttons{
    display: flex;
    justify-content: center;
    gap: 20px;
}

.cookies-buttons div{
    border: 2px solid white;
    border-radius: 20px;
}

.cookies-banner a{
    color: #FFF;
    font-weight: bold;
}

.close-cookies-banner{
    cursor: pointer;
    height: fit-content;
}

@media (max-width: 500px){
    .cookies-banner{
        position: fixed;
        bottom: 0;
        left: 50%;
        max-width: 700px;
        width: 100%;
        min-height: 65vh;
        font-size: 1.1rem;
        transform: translateX(-50%);
        background-color: var(--rubikfly-blue);
        color: #FFF;
        padding: 10px;
        padding-top: 40px;
        z-index: 9;
        text-align: center;
    }
}

.cookies-policy, .legal-advice, .privacy-policy{
    max-width: 1400px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.cookies-policy h1, .legal-advice h1, .privacy-policy h1{
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 50px;
}

.cookies-policy h2, .legal-advice h2, .privacy-policy h2{
    font-size: 1.5rem;
    font-weight: bold;
}

.cookies-policy h3, .legal-advice h3, .privacy-policy h3{
    font-size: 1.3rem;
    font-weight: bold;
}

.cookies-policy h4, .legal-advice h4, .privacy-policy h4{
    font-size: 1.1rem;
    font-weight: bold;
}

footer .copyright{
    margin-top: 100px;
}

/*How it works*/

.howitworks-view .step__number{
    font-size: 3rem;
    font-weight: bold;
    color: var(--rubikfly-blue);
}

.howitworks-view h1{
    max-width: 1400px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
}
.howitworks-view h5{
    margin-top: 50px;
    max-width: 1400px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
}

.howitworks-view h3{
    font-weight: bold;
}

.howitworks-view .destinations-container{
    margin-top: 50px;
}

.howitworks-view .steps{
    max-width: 1400px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

/*FAQ*/

.faq{
    max-width: 1000px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    margin-bottom: 200px;
}

.faq h1{
    font-weight: bold;
}

.faq h5{
    font-weight: bold;
}

.faq .question{
    /*background-color: #EEE;*/
    margin-top: 40px;
    overflow: hidden;
    box-shadow: 0 0 5px #666;
    border-radius: 5px;
}

.faq h3{
    padding: 20px 20px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    color: #000000;
    margin-bottom: 0;
}

.faq h3:hover{
    background-color: var(--rubikfly-blue);
    color: white;
}

.faq p{
    padding: 20px 40px;
}

.faq .question__title{
    display: flex;
    justify-content: space-between;
}

.faq .question__title-text{
    padding-right: 40px;
}

.faq .question__title img{
    width: 20px;
}

.faq .up-down-img{
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

/*Work with us*/

.work-with-us{
    max-width: 1000px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    margin-bottom: 200px;
}

.work-with-us h1{
    font-weight: bold;
}

.work-with-us h5{
    font-weight: bold;
}

/*Contact*/
.contact-header{
    width: 100%;
    height: 400px;
    background-color: antiquewhite;
    background-image: url("/imgs/bg-contacta.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
}

.contact-info{
    width: 90%;
    margin: auto;
}

.contact-form{
    width: 90%;
    margin: auto;
    background-color: #EEE;
    border-radius: 20px;
    box-shadow: 0 0 10px;
    margin-top: -30px;
    padding-top: 20px;
    z-index: 2;
}

.contact-form textarea{
    resize: none;
    width: 100%;
    max-width: 500px;
    border-radius: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 5px;
}

.contact-form input{
    width: 100%;
    max-width: 500px;
    border-radius: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 5px;
}

.contact-form div{
    width: 90%;
    margin: auto;
    justify-content: center;
}

.contact-body{
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: 1200px;
    width: 95%;
    margin: auto;
    text-align: justify;
}

@media (max-width: 500px){
    .contact-body{
        display: grid;
        grid-template-columns: 100%;
        margin: auto;
        text-align: justify;
    }
}

.contact-form .checkbox input{
    width: fit-content;
}
.contact-form .checkbox label{
    display: inline;
}

.contact-form button{
    width: 100%;
    border-radius: 0 0 10px 10px;
    background-color: var(--rubikfly-blue);
    color: #FFF;
    padding: 10px;
    border: none;
}

.contact-form input:focus{
    outline: none;
    border-bottom: 2px solid var(--rubikfly-blue);
}

.contact-form textarea:focus{
    outline: none;
    border-bottom: 2px solid var(--rubikfly-blue);
}


@media (max-width: 800px){
    .hide-on-small{
        display: none;
    }

    .show-on-small-only{
        display: block;
    }
}

@media (min-width: 801px){

    .show-on-med{
        display: block;
    }

    .hide-on-med{
        display: none;
    }

}

/*ADMIN*/

.admin-layout{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.admin-main-layout{
    flex: 1 1 0;
    height: 100%;
}

.h100{
    height: 100%;
}

.margin0{
    margin: 0;
}

.pointer{
    cursor: pointer;
}

.admin-view{
    height: 100%;
    display: flex;
    flex-direction: row;
}

.admin-view .admin-left-menu{
    height: 100%;
    width: 250px;
    box-shadow: 0 0 5px #999;
    padding: 20px;
}

.admin-view .admin-left-menu ul{
    list-style: none;
    padding: 0;
}

.admin-view .admin-left-menu ul li{
    padding: 5px 0;
}

.admin-view .admin-left-menu .sublist{
    padding-left: 10px;
}

.admin-view .admin-router-view{
    flex: 1 1 0;
}

/*Airports*/

.airports-component{
    padding: 20px;
}
.airports-component table{
    max-width: 1000px;
    width: 90%;
    margin: auto;
}

.airports-component .disabled-input{
    border: none;
    background-color: white;
}

.airports-component .edit{
    cursor: pointer;
}

.airports-component .add-airport-button-container{
    width: fit-content;
    margin: 30px auto;
}

.airports-component .add-airport-button{
    padding: 10px 30px;
    background: var(--button-background);
    border: none;
    color: white;
    border-radius: 20px;
}

/*Extra Prices*/

.extra-prices-component table{
    max-width: 1000px;
    width: 90%;
    margin: auto;
}

.extra-prices-component .disabled-input{
    border: none;
    background-color: white;
}

.extra-prices-component .edit{
    cursor: pointer;
}

/*Calendars*/

.calendars-view{
    padding: 10px;
    max-width: 800px;
    margin: auto;
}

.calendars-view h1{
    text-align: center;
}

.calendars-view h2{
    margin-top: 30px;
}

/*Bookings*/

.bookings-view .table{
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    border-collapse: unset;
}

